// source: greenhouse.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.State', null, global);
goog.exportSymbol('proto.Timeseries', null, global);
goog.exportSymbol('proto.TimeseriesRequest', null, global);
goog.exportSymbol('proto.Timestamp', null, global);
goog.exportSymbol('proto.Timing', null, global);
goog.exportSymbol('proto.WaterRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.State.displayName = 'proto.State';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Timing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Timing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Timing.displayName = 'proto.Timing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Timestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Timestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Timestamp.displayName = 'proto.Timestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Timeseries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Timeseries.repeatedFields_, null);
};
goog.inherits(proto.Timeseries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Timeseries.displayName = 'proto.Timeseries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeseriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TimeseriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeseriesRequest.displayName = 'proto.TimeseriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WaterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.WaterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WaterRequest.displayName = 'proto.WaterRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.State.prototype.toObject = function(opt_includeInstance) {
  return proto.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    lights: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    brightnesspercent1: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    brightnesspercent2: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lightslast: jspb.Message.getFieldWithDefault(msg, 4, 0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    humidity: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    soilcapacitance: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 8, 0),
    wateredtoday: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    lightstiming: (f = msg.getLightstiming()) && proto.Timing.toObject(includeInstance, f),
    recirculationfan: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    recirculationfanlast: jspb.Message.getFieldWithDefault(msg, 12, 0),
    ventfan: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    ventfanlast: jspb.Message.getFieldWithDefault(msg, 14, 0),
    ventoverridden: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    wateredlast: jspb.Message.getFieldWithDefault(msg, 16, 0),
    lightsoverridden: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    ramppercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    maxautomationbrightness: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    deviceid: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.State}
 */
proto.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.State;
  return proto.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.State}
 */
proto.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLights(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBrightnesspercent1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBrightnesspercent2(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLightslast(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHumidity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSoilcapacitance(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWateredtoday(value);
      break;
    case 10:
      var value = new proto.Timing;
      reader.readMessage(value,proto.Timing.deserializeBinaryFromReader);
      msg.setLightstiming(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecirculationfan(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecirculationfanlast(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVentfan(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVentfanlast(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVentoverridden(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWateredlast(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLightsoverridden(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRamppercentage(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxautomationbrightness(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLights();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBrightnesspercent1();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBrightnesspercent2();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getLightslast();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getHumidity();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getSoilcapacitance();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getWateredtoday();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLightstiming();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.Timing.serializeBinaryToWriter
    );
  }
  f = message.getRecirculationfan();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getRecirculationfanlast();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getVentfan();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getVentfanlast();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getVentoverridden();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getWateredlast();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getLightsoverridden();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getRamppercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      18,
      f
    );
  }
  f = message.getMaxautomationbrightness();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
};


/**
 * optional bool lights = 1;
 * @return {boolean}
 */
proto.State.prototype.getLights = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setLights = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional float brightnessPercent1 = 2;
 * @return {number}
 */
proto.State.prototype.getBrightnesspercent1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setBrightnesspercent1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float brightnessPercent2 = 3;
 * @return {number}
 */
proto.State.prototype.getBrightnesspercent2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setBrightnesspercent2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint32 lightsLast = 4;
 * @return {number}
 */
proto.State.prototype.getLightslast = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setLightslast = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float temperature = 5;
 * @return {number}
 */
proto.State.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float humidity = 6;
 * @return {number}
 */
proto.State.prototype.getHumidity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setHumidity = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float soilCapacitance = 7;
 * @return {number}
 */
proto.State.prototype.getSoilcapacitance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setSoilcapacitance = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 timestamp = 8;
 * @return {number}
 */
proto.State.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool wateredToday = 9;
 * @return {boolean}
 */
proto.State.prototype.getWateredtoday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setWateredtoday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional Timing lightsTiming = 10;
 * @return {?proto.Timing}
 */
proto.State.prototype.getLightstiming = function() {
  return /** @type{?proto.Timing} */ (
    jspb.Message.getWrapperField(this, proto.Timing, 10));
};


/**
 * @param {?proto.Timing|undefined} value
 * @return {!proto.State} returns this
*/
proto.State.prototype.setLightstiming = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.State} returns this
 */
proto.State.prototype.clearLightstiming = function() {
  return this.setLightstiming(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.State.prototype.hasLightstiming = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool recirculationFan = 11;
 * @return {boolean}
 */
proto.State.prototype.getRecirculationfan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setRecirculationfan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional uint32 recirculationFanLast = 12;
 * @return {number}
 */
proto.State.prototype.getRecirculationfanlast = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setRecirculationfanlast = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool ventFan = 13;
 * @return {boolean}
 */
proto.State.prototype.getVentfan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setVentfan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional uint32 ventFanLast = 14;
 * @return {number}
 */
proto.State.prototype.getVentfanlast = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setVentfanlast = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool ventOverridden = 15;
 * @return {boolean}
 */
proto.State.prototype.getVentoverridden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setVentoverridden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional uint32 wateredLast = 16;
 * @return {number}
 */
proto.State.prototype.getWateredlast = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setWateredlast = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bool lightsOverridden = 17;
 * @return {boolean}
 */
proto.State.prototype.getLightsoverridden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setLightsoverridden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional float rampPercentage = 18;
 * @return {number}
 */
proto.State.prototype.getRamppercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setRamppercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional float maxAutomationBrightness = 19;
 * @return {number}
 */
proto.State.prototype.getMaxautomationbrightness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setMaxautomationbrightness = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional int32 deviceID = 20;
 * @return {number}
 */
proto.State.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.State} returns this
 */
proto.State.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Timing.prototype.toObject = function(opt_includeInstance) {
  return proto.Timing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Timing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Timing.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && proto.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Timing}
 */
proto.Timing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Timing;
  return proto.Timing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Timing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Timing}
 */
proto.Timing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Timestamp;
      reader.readMessage(value,proto.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.Timestamp;
      reader.readMessage(value,proto.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Timing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Timing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Timing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Timing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional Timestamp start = 1;
 * @return {?proto.Timestamp}
 */
proto.Timing.prototype.getStart = function() {
  return /** @type{?proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.Timestamp, 1));
};


/**
 * @param {?proto.Timestamp|undefined} value
 * @return {!proto.Timing} returns this
*/
proto.Timing.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Timing} returns this
 */
proto.Timing.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Timing.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Timestamp end = 2;
 * @return {?proto.Timestamp}
 */
proto.Timing.prototype.getEnd = function() {
  return /** @type{?proto.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.Timestamp, 2));
};


/**
 * @param {?proto.Timestamp|undefined} value
 * @return {!proto.Timing} returns this
*/
proto.Timing.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Timing} returns this
 */
proto.Timing.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Timing.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Timestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.Timestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Timestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Timestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    seconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nanos: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Timestamp}
 */
proto.Timestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Timestamp;
  return proto.Timestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Timestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Timestamp}
 */
proto.Timestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNanos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Timestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Timestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Timestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Timestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNanos();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 seconds = 1;
 * @return {number}
 */
proto.Timestamp.prototype.getSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Timestamp} returns this
 */
proto.Timestamp.prototype.setSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 nanos = 2;
 * @return {number}
 */
proto.Timestamp.prototype.getNanos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Timestamp} returns this
 */
proto.Timestamp.prototype.setNanos = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Timeseries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Timeseries.prototype.toObject = function(opt_includeInstance) {
  return proto.Timeseries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Timeseries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Timeseries.toObject = function(includeInstance, msg) {
  var f, obj = {
    statesList: jspb.Message.toObjectList(msg.getStatesList(),
    proto.State.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Timeseries}
 */
proto.Timeseries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Timeseries;
  return proto.Timeseries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Timeseries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Timeseries}
 */
proto.Timeseries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.State;
      reader.readMessage(value,proto.State.deserializeBinaryFromReader);
      msg.addStates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Timeseries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Timeseries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Timeseries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Timeseries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.State.serializeBinaryToWriter
    );
  }
};


/**
 * repeated State states = 1;
 * @return {!Array<!proto.State>}
 */
proto.Timeseries.prototype.getStatesList = function() {
  return /** @type{!Array<!proto.State>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.State, 1));
};


/**
 * @param {!Array<!proto.State>} value
 * @return {!proto.Timeseries} returns this
*/
proto.Timeseries.prototype.setStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.State=} opt_value
 * @param {number=} opt_index
 * @return {!proto.State}
 */
proto.Timeseries.prototype.addStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.State, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Timeseries} returns this
 */
proto.Timeseries.prototype.clearStatesList = function() {
  return this.setStatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeseriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeseriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeseriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeseriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deviceid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeseriesRequest}
 */
proto.TimeseriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeseriesRequest;
  return proto.TimeseriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeseriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeseriesRequest}
 */
proto.TimeseriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeseriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeseriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeseriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeseriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 from = 1;
 * @return {number}
 */
proto.TimeseriesRequest.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeseriesRequest} returns this
 */
proto.TimeseriesRequest.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 to = 2;
 * @return {number}
 */
proto.TimeseriesRequest.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeseriesRequest} returns this
 */
proto.TimeseriesRequest.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 deviceID = 3;
 * @return {number}
 */
proto.TimeseriesRequest.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimeseriesRequest} returns this
 */
proto.TimeseriesRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WaterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.WaterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WaterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WaterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WaterRequest}
 */
proto.WaterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WaterRequest;
  return proto.WaterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WaterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WaterRequest}
 */
proto.WaterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WaterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WaterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WaterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WaterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 deviceID = 2;
 * @return {number}
 */
proto.WaterRequest.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.WaterRequest} returns this
 */
proto.WaterRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto);
