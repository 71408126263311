/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v5.27.1
// source: coop.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as coop_pb from './coop_pb';


export class CoopClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetCoopState = new grpcWeb.MethodDescriptor(
    '/Coop/GetCoopState',
    grpcWeb.MethodType.UNARY,
    coop_pb.CoopState,
    coop_pb.CoopState,
    (request: coop_pb.CoopState) => {
      return request.serializeBinary();
    },
    coop_pb.CoopState.deserializeBinary
  );

  getCoopState(
    request: coop_pb.CoopState,
    metadata: grpcWeb.Metadata | null): Promise<coop_pb.CoopState>;

  getCoopState(
    request: coop_pb.CoopState,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: coop_pb.CoopState) => void): grpcWeb.ClientReadableStream<coop_pb.CoopState>;

  getCoopState(
    request: coop_pb.CoopState,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: coop_pb.CoopState) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Coop/GetCoopState',
        request,
        metadata || {},
        this.methodDescriptorGetCoopState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Coop/GetCoopState',
    request,
    metadata || {},
    this.methodDescriptorGetCoopState);
  }

  methodDescriptorSetCoopState = new grpcWeb.MethodDescriptor(
    '/Coop/SetCoopState',
    grpcWeb.MethodType.UNARY,
    coop_pb.CoopState,
    coop_pb.CoopState,
    (request: coop_pb.CoopState) => {
      return request.serializeBinary();
    },
    coop_pb.CoopState.deserializeBinary
  );

  setCoopState(
    request: coop_pb.CoopState,
    metadata: grpcWeb.Metadata | null): Promise<coop_pb.CoopState>;

  setCoopState(
    request: coop_pb.CoopState,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: coop_pb.CoopState) => void): grpcWeb.ClientReadableStream<coop_pb.CoopState>;

  setCoopState(
    request: coop_pb.CoopState,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: coop_pb.CoopState) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Coop/SetCoopState',
        request,
        metadata || {},
        this.methodDescriptorSetCoopState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Coop/SetCoopState',
    request,
    metadata || {},
    this.methodDescriptorSetCoopState);
  }

  methodDescriptorCoopWatered = new grpcWeb.MethodDescriptor(
    '/Coop/CoopWatered',
    grpcWeb.MethodType.UNARY,
    coop_pb.CoopWaterRequest,
    coop_pb.CoopWaterRequest,
    (request: coop_pb.CoopWaterRequest) => {
      return request.serializeBinary();
    },
    coop_pb.CoopWaterRequest.deserializeBinary
  );

  coopWatered(
    request: coop_pb.CoopWaterRequest,
    metadata: grpcWeb.Metadata | null): Promise<coop_pb.CoopWaterRequest>;

  coopWatered(
    request: coop_pb.CoopWaterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: coop_pb.CoopWaterRequest) => void): grpcWeb.ClientReadableStream<coop_pb.CoopWaterRequest>;

  coopWatered(
    request: coop_pb.CoopWaterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: coop_pb.CoopWaterRequest) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Coop/CoopWatered',
        request,
        metadata || {},
        this.methodDescriptorCoopWatered,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Coop/CoopWatered',
    request,
    metadata || {},
    this.methodDescriptorCoopWatered);
  }

  methodDescriptorGetCoopTimeseries = new grpcWeb.MethodDescriptor(
    '/Coop/GetCoopTimeseries',
    grpcWeb.MethodType.UNARY,
    coop_pb.CoopTimeseriesRequest,
    coop_pb.CoopTimeseries,
    (request: coop_pb.CoopTimeseriesRequest) => {
      return request.serializeBinary();
    },
    coop_pb.CoopTimeseries.deserializeBinary
  );

  getCoopTimeseries(
    request: coop_pb.CoopTimeseriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<coop_pb.CoopTimeseries>;

  getCoopTimeseries(
    request: coop_pb.CoopTimeseriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: coop_pb.CoopTimeseries) => void): grpcWeb.ClientReadableStream<coop_pb.CoopTimeseries>;

  getCoopTimeseries(
    request: coop_pb.CoopTimeseriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: coop_pb.CoopTimeseries) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Coop/GetCoopTimeseries',
        request,
        metadata || {},
        this.methodDescriptorGetCoopTimeseries,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Coop/GetCoopTimeseries',
    request,
    metadata || {},
    this.methodDescriptorGetCoopTimeseries);
  }

}

