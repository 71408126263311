// source: coop.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.CoopState', null, global);
goog.exportSymbol('proto.CoopTimeseries', null, global);
goog.exportSymbol('proto.CoopTimeseriesRequest', null, global);
goog.exportSymbol('proto.CoopTimestamp', null, global);
goog.exportSymbol('proto.CoopTiming', null, global);
goog.exportSymbol('proto.CoopWaterRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoopState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoopState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CoopState.displayName = 'proto.CoopState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoopTiming = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoopTiming, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CoopTiming.displayName = 'proto.CoopTiming';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoopTimestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoopTimestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CoopTimestamp.displayName = 'proto.CoopTimestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoopTimeseries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CoopTimeseries.repeatedFields_, null);
};
goog.inherits(proto.CoopTimeseries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CoopTimeseries.displayName = 'proto.CoopTimeseries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoopTimeseriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoopTimeseriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CoopTimeseriesRequest.displayName = 'proto.CoopTimeseriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CoopWaterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CoopWaterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CoopWaterRequest.displayName = 'proto.CoopWaterRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CoopState.prototype.toObject = function(opt_includeInstance) {
  return proto.CoopState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CoopState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopState.toObject = function(includeInstance, msg) {
  var f, obj = {
    lights: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    brightness: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    red: jspb.Message.getFieldWithDefault(msg, 3, 0),
    green: jspb.Message.getFieldWithDefault(msg, 4, 0),
    blue: jspb.Message.getFieldWithDefault(msg, 5, 0),
    white: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lightslast: jspb.Message.getFieldWithDefault(msg, 7, 0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    humidity: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    gateposition: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 11, 0),
    wateredtoday: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    lightstiming: (f = msg.getLightstiming()) && proto.CoopTiming.toObject(includeInstance, f),
    recirculationfan: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    recirculationfanlast: jspb.Message.getFieldWithDefault(msg, 15, 0),
    ventfan: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    ventfanlast: jspb.Message.getFieldWithDefault(msg, 17, 0),
    ventoverridden: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    wateredlast: jspb.Message.getFieldWithDefault(msg, 19, 0),
    lightsoverridden: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    ramppercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    maxautomationbrightness: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    deviceid: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoopState}
 */
proto.CoopState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CoopState;
  return proto.CoopState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoopState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoopState}
 */
proto.CoopState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLights(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBrightness(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGreen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWhite(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLightslast(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHumidity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGateposition(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWateredtoday(value);
      break;
    case 13:
      var value = new proto.CoopTiming;
      reader.readMessage(value,proto.CoopTiming.deserializeBinaryFromReader);
      msg.setLightstiming(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecirculationfan(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecirculationfanlast(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVentfan(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVentfanlast(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVentoverridden(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWateredlast(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLightsoverridden(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRamppercentage(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxautomationbrightness(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoopState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CoopState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoopState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLights();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBrightness();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRed();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getGreen();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBlue();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getWhite();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLightslast();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getHumidity();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getGateposition();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getWateredtoday();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getLightstiming();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.CoopTiming.serializeBinaryToWriter
    );
  }
  f = message.getRecirculationfan();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getRecirculationfanlast();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getVentfan();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getVentfanlast();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getVentoverridden();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getWateredlast();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getLightsoverridden();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getRamppercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getMaxautomationbrightness();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
};


/**
 * optional bool lights = 1;
 * @return {boolean}
 */
proto.CoopState.prototype.getLights = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setLights = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional float brightness = 2;
 * @return {number}
 */
proto.CoopState.prototype.getBrightness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setBrightness = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint32 red = 3;
 * @return {number}
 */
proto.CoopState.prototype.getRed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setRed = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 green = 4;
 * @return {number}
 */
proto.CoopState.prototype.getGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 blue = 5;
 * @return {number}
 */
proto.CoopState.prototype.getBlue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setBlue = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 white = 6;
 * @return {number}
 */
proto.CoopState.prototype.getWhite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setWhite = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 lightsLast = 7;
 * @return {number}
 */
proto.CoopState.prototype.getLightslast = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setLightslast = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional float temperature = 8;
 * @return {number}
 */
proto.CoopState.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float humidity = 9;
 * @return {number}
 */
proto.CoopState.prototype.getHumidity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setHumidity = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float gatePosition = 10;
 * @return {number}
 */
proto.CoopState.prototype.getGateposition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setGateposition = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional int64 timestamp = 11;
 * @return {number}
 */
proto.CoopState.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool wateredToday = 12;
 * @return {boolean}
 */
proto.CoopState.prototype.getWateredtoday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setWateredtoday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional CoopTiming lightsTiming = 13;
 * @return {?proto.CoopTiming}
 */
proto.CoopState.prototype.getLightstiming = function() {
  return /** @type{?proto.CoopTiming} */ (
    jspb.Message.getWrapperField(this, proto.CoopTiming, 13));
};


/**
 * @param {?proto.CoopTiming|undefined} value
 * @return {!proto.CoopState} returns this
*/
proto.CoopState.prototype.setLightstiming = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.clearLightstiming = function() {
  return this.setLightstiming(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoopState.prototype.hasLightstiming = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool recirculationFan = 14;
 * @return {boolean}
 */
proto.CoopState.prototype.getRecirculationfan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setRecirculationfan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional uint32 recirculationFanLast = 15;
 * @return {number}
 */
proto.CoopState.prototype.getRecirculationfanlast = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setRecirculationfanlast = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional bool ventFan = 16;
 * @return {boolean}
 */
proto.CoopState.prototype.getVentfan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setVentfan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional uint32 ventFanLast = 17;
 * @return {number}
 */
proto.CoopState.prototype.getVentfanlast = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setVentfanlast = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional bool ventOverridden = 18;
 * @return {boolean}
 */
proto.CoopState.prototype.getVentoverridden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setVentoverridden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional uint32 wateredLast = 19;
 * @return {number}
 */
proto.CoopState.prototype.getWateredlast = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setWateredlast = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional bool lightsOverridden = 20;
 * @return {boolean}
 */
proto.CoopState.prototype.getLightsoverridden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setLightsoverridden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional float rampPercentage = 21;
 * @return {number}
 */
proto.CoopState.prototype.getRamppercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setRamppercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional float maxAutomationBrightness = 22;
 * @return {number}
 */
proto.CoopState.prototype.getMaxautomationbrightness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setMaxautomationbrightness = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional int32 deviceID = 23;
 * @return {number}
 */
proto.CoopState.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopState} returns this
 */
proto.CoopState.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CoopTiming.prototype.toObject = function(opt_includeInstance) {
  return proto.CoopTiming.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CoopTiming} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopTiming.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && proto.CoopTimestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && proto.CoopTimestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoopTiming}
 */
proto.CoopTiming.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CoopTiming;
  return proto.CoopTiming.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoopTiming} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoopTiming}
 */
proto.CoopTiming.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CoopTimestamp;
      reader.readMessage(value,proto.CoopTimestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new proto.CoopTimestamp;
      reader.readMessage(value,proto.CoopTimestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoopTiming.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CoopTiming.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoopTiming} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopTiming.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.CoopTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.CoopTimestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional CoopTimestamp start = 1;
 * @return {?proto.CoopTimestamp}
 */
proto.CoopTiming.prototype.getStart = function() {
  return /** @type{?proto.CoopTimestamp} */ (
    jspb.Message.getWrapperField(this, proto.CoopTimestamp, 1));
};


/**
 * @param {?proto.CoopTimestamp|undefined} value
 * @return {!proto.CoopTiming} returns this
*/
proto.CoopTiming.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CoopTiming} returns this
 */
proto.CoopTiming.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoopTiming.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CoopTimestamp end = 2;
 * @return {?proto.CoopTimestamp}
 */
proto.CoopTiming.prototype.getEnd = function() {
  return /** @type{?proto.CoopTimestamp} */ (
    jspb.Message.getWrapperField(this, proto.CoopTimestamp, 2));
};


/**
 * @param {?proto.CoopTimestamp|undefined} value
 * @return {!proto.CoopTiming} returns this
*/
proto.CoopTiming.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CoopTiming} returns this
 */
proto.CoopTiming.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CoopTiming.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CoopTimestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.CoopTimestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CoopTimestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopTimestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    seconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nanos: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoopTimestamp}
 */
proto.CoopTimestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CoopTimestamp;
  return proto.CoopTimestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoopTimestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoopTimestamp}
 */
proto.CoopTimestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNanos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoopTimestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CoopTimestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoopTimestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopTimestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNanos();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 seconds = 1;
 * @return {number}
 */
proto.CoopTimestamp.prototype.getSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopTimestamp} returns this
 */
proto.CoopTimestamp.prototype.setSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 nanos = 2;
 * @return {number}
 */
proto.CoopTimestamp.prototype.getNanos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopTimestamp} returns this
 */
proto.CoopTimestamp.prototype.setNanos = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CoopTimeseries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CoopTimeseries.prototype.toObject = function(opt_includeInstance) {
  return proto.CoopTimeseries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CoopTimeseries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopTimeseries.toObject = function(includeInstance, msg) {
  var f, obj = {
    statesList: jspb.Message.toObjectList(msg.getStatesList(),
    proto.CoopState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoopTimeseries}
 */
proto.CoopTimeseries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CoopTimeseries;
  return proto.CoopTimeseries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoopTimeseries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoopTimeseries}
 */
proto.CoopTimeseries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.CoopState;
      reader.readMessage(value,proto.CoopState.deserializeBinaryFromReader);
      msg.addStates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoopTimeseries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CoopTimeseries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoopTimeseries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopTimeseries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.CoopState.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CoopState states = 1;
 * @return {!Array<!proto.CoopState>}
 */
proto.CoopTimeseries.prototype.getStatesList = function() {
  return /** @type{!Array<!proto.CoopState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.CoopState, 1));
};


/**
 * @param {!Array<!proto.CoopState>} value
 * @return {!proto.CoopTimeseries} returns this
*/
proto.CoopTimeseries.prototype.setStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.CoopState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CoopState}
 */
proto.CoopTimeseries.prototype.addStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.CoopState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CoopTimeseries} returns this
 */
proto.CoopTimeseries.prototype.clearStatesList = function() {
  return this.setStatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CoopTimeseriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CoopTimeseriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CoopTimeseriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopTimeseriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deviceid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoopTimeseriesRequest}
 */
proto.CoopTimeseriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CoopTimeseriesRequest;
  return proto.CoopTimeseriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoopTimeseriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoopTimeseriesRequest}
 */
proto.CoopTimeseriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoopTimeseriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CoopTimeseriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoopTimeseriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopTimeseriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int64 from = 1;
 * @return {number}
 */
proto.CoopTimeseriesRequest.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopTimeseriesRequest} returns this
 */
proto.CoopTimeseriesRequest.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 to = 2;
 * @return {number}
 */
proto.CoopTimeseriesRequest.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopTimeseriesRequest} returns this
 */
proto.CoopTimeseriesRequest.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 deviceID = 3;
 * @return {number}
 */
proto.CoopTimeseriesRequest.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopTimeseriesRequest} returns this
 */
proto.CoopTimeseriesRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CoopWaterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CoopWaterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CoopWaterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopWaterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CoopWaterRequest}
 */
proto.CoopWaterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CoopWaterRequest;
  return proto.CoopWaterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CoopWaterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CoopWaterRequest}
 */
proto.CoopWaterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CoopWaterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CoopWaterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CoopWaterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CoopWaterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 deviceID = 2;
 * @return {number}
 */
proto.CoopWaterRequest.prototype.getDeviceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.CoopWaterRequest} returns this
 */
proto.CoopWaterRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto);
